import React from 'react'
import links from 'links'
import { track } from 'analytics'
import { useSubscription } from 'modules/subscription'
import { useUser } from 'modules/user'

import { useReferralInvites } from 'modules/user'
import { WidthContainer } from 'components/layout'

import { NavItem } from 'compositions/navigation'
import { navMessages as messages } from 'compositions/navigation'

import TrackingLink from './components/TrackingLink/TrackingLink'


const SupNav: React.FunctionComponent = () => {
  const { isLoggedIn } = useUser()

  const { stats: { available } } = useReferralInvites()
  const { subscription } = useSubscription()
  const isSubscribed = subscription?.isSubscribed

  if (!isLoggedIn) {
    return null
  }

  const getReferralLink = () => {
    const handleClick = () => {
      track('Banner click', {
        placement: 'Header',
        content: 'Referral program',
        action: 'link',
        link: links.invite,
      })
    }

    if (available) {
      return (
        <NavItem
          className="flex items-center gap-8"
          to={links.invite}
          title={{
            ...messages.newReferral,
            values: {
              available,
              freeFragranceClassName: '',
              spanClassName: 'bg-white text-black',
            },
          }}
          style="p4"
          withArrow
          onClick={handleClick}
        />
      )
    }

    return <div />
  }

  return (
    <div className="bg-gold-30 desktop:z-desktop-sup-header">
      <WidthContainer className="flex w-full items-center justify-between py-8">
        {getReferralLink()}
        <div>
          <TrackingLink />
          {
            isSubscribed && (
              <NavItem
                className="ml-24 inline-block"
                style="p4"
                to={links.queue}
                title={messages.queue}
                onClick={() => {
                  track('Manage your queue click', {
                    placement: 'Header',
                  })
                }}
              />
            )
          }
        </div>
      </WidthContainer>
    </div>
  )
}


export default SupNav
