export default {
  perfumes: {
    title: {
      en: `
        Spotlight on:<br />
        <span class="text-gold-50">Skylar</span>
      `,
    },
    text: {
      en: `
        Balancing perfumery with<br />
        hypoallergenic formulations,<br />
        Skylar proves that you can have<br />
        safe, clean scents that smell<br />
        utterly alluring.
      `,
    },
  },
  colognes: {
    title: {
      en: `
        Spotlight on:<br />
        <span class="text-gold-50">Parfums De Marly</span>
      `,
    },
    text: {
      en: `
        Crafted from a palette of noble<br />
        ingredients, Parfums de Marly<br />
        compositions are a meld of lavish<br />
        18th-century splendor, tailor-<br />
        made for the pure enjoyment.
      `,
    },
  },
}
