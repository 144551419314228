import React from 'react'
import links from 'links'
import { useSubscribeOffer } from 'modules/subscription'

import SubscribeHeaderBannerBaseAb from 'compositions/banners/SubscribeHeaderBannerBaseAb/SubscribeHeaderBannerBaseAb'

import messages from './messages'


type TextByOffer = Record<SubscriptionModule.OfferType, Intl.Message>

type SubscribeHeaderBannerAbProps = {
  placement?: 'Header' | 'Under logo'
}

const textByOffer: TextByOffer = {
  freeTrial: messages.freeTrial,
  free2ndMonth: messages.freeMonth,
  freeProduct: messages.freeProduct,
  discount: messages.discount,
}

const SubscribeHeaderBannerAb: React.FC<SubscribeHeaderBannerAbProps> = (props) => {
  const { placement = 'Header' } = props

  const {
    isFetching,
    discountText,
    eventDiscountText,
    couponCode,
    offerType,
    handleSubscribeClick,
  } = useSubscribeOffer()


  const title = {
    ...textByOffer[offerType] || messages.noDiscountText,
    values: {
      discount: discountText,
    },
  }

  return (
    <SubscribeHeaderBannerBaseAb
      title={title}
      loading={isFetching}
      trackParams={{
        placement,
        content: 'Subscribe',
        action: 'link',
        link: links.subscribe,
        discount: eventDiscountText,
        coupon: couponCode,
      }}
      onClick={handleSubscribeClick}
    />
  )
}


export default SubscribeHeaderBannerAb
