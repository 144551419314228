export default {
  title: {
    en: 'Get more and then\nsave more',
  },
  text: {
    en: `
      Instead of waiting month after\nmonth, buy your entire queue\nand save on the lot.
      Unlock 5%\nwhen you add three items to\nyour cart, and 10% if you buy six.
    `,
  },
  buttonTitle: {
    en: 'Buy your queue and save',
  },
}
