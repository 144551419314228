export default {
  discount: {
    en: 'Complete your subscription<br class="onlyMobile"/> purchase with {discount} off!',
  },
  freeTrial: {
    en: 'Get your FREE perfume now',
  },
  freeProduct: {
    en: 'Subscribe and unlock<br class="onlyMobile"/> your FREE product',
  },
  freeMonth: {
    en: 'Unlock your second month<br class="onlyMobile"/> for FREE',
  },
  noDiscountText: {
    en: 'Complete your subscription<br class="onlyMobile"/> purchase.',
  },
}
