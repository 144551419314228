import React from 'react'
import links from 'links'

import BannerBase from 'compositions/banners/BannerBase/BannerBase'

import messages from './messages'

import bgImage from './images/bg.jpg'


const GiftSubscriptionBanner: React.FC<{ onClick?: () => void }> = ({ onClick }) => {
  return (
    <BannerBase
      to={links.giftSubscription}
      title={messages.title}
      text={messages.text}
      bgImage={bgImage}
      buttonTitle={messages.buttonTitle}
      content="Gift subscription"
      sizes="560px"
      onClick={onClick}
      data-testid="giftSubscriptionBanner"
    />
  )
}


export default GiftSubscriptionBanner
