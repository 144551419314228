import React from 'react'
import links from 'links'

import BannerBase from 'compositions/banners/BannerBase/BannerBase'

import messages from './messages'

import bgImage from './images/bg.jpg'


const CasesBanner: React.FC<{ onClick?: () => void }> = ({ onClick }) => {
  return (
    <BannerBase
      to={links.shop.scentbirdCases}
      title={messages.title}
      titleColor="white"
      text={messages.text}
      textColor="white"
      bgImage={bgImage}
      buttonTitle={messages.buttonTitle}
      content="Cases"
      sizes="560px"
      onClick={onClick}
      data-testid="casesBanner"
    />
  )
}


export default CasesBanner
