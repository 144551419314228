export default {
  title: {
    en: 'Make a case for\nsomething new',
  },
  text: {
    en: `
      Month after month. Upgrade your\naccount to include a new atomizer\ncase
      in every order for an extra {price}\nper month, and ensure your scents\nare always covered.
    `,
  },
  buttonTitle: {
    en: 'Subscribe to monthly cases',
  },
}
