import React from 'react'
import links from 'links'
import { useIntl } from 'intl'

import { useCaseSubscription } from 'modules/subscription'

import BannerBase from 'compositions/banners/BannerBase/BannerBase'

import messages from './messages'

import bgImage from './images/bg.jpg'


const CaseSubscriptionBanner: React.FC<{ onClick?: () => void }> = ({ onClick }) => {
  const { caseSubscription } = useCaseSubscription()

  const intl = useIntl()
  // fallback just in case of re-fetching
  const price = intl.formatPrice(caseSubscription?.price || 1000)

  return (
    <BannerBase
      to={links.caseSubscription}
      title={messages.title}
      titleColor="white"
      text={{ ...messages.text, values: { price } }}
      textColor="white"
      bgImage={bgImage}
      buttonTitle={messages.buttonTitle}
      content="Case subscription"
      sizes="560px"
      onClick={onClick}
      data-testid="caseSubscriptionBanner"
    />
  )
}


export default CaseSubscriptionBanner
