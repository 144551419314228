import React from 'react'
import links from 'links'

import { buttonMessages } from 'components/inputs'

import BannerBase from 'compositions/banners/BannerBase/BannerBase'

import messages from './messages'
import bgImage from './images/bg.jpg'


const CandleSubscriptionBanner: React.FC<{ onClick?: () => void }> = ({ onClick }) => {
  return (
    <BannerBase
      to={links.candleSubscription}
      title={messages.title}
      titleColor="white"
      text={messages.text}
      textColor="white"
      bgImage={bgImage}
      buttonTitle={buttonMessages.learnMore}
      buttonStyle="gold-30"
      content="Candle subscription"
      sizes="560px"
      onClick={onClick}
      data-testid="candleSubscriptionBanner"
    />
  )
}


export default CandleSubscriptionBanner
