export default {
  title: {
    en: 'Introducing our\nCandle Club',
  },
  text: {
    en: `
      Everything is illuminated, thanks to\nour luxe scented candles you'll\nreceive each month. Retailing at up\nto $75, these will light your fire.
    `,
  },
}
