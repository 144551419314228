export default {
  title: {
    en: 'Shop the Edit',
  },
  text: {
    en: `
      Immerse yourself in scent<br />
      beyond the bottle with<br />
      candles, body lotions,<br />
      diffusers, and so much more.
    `,
  },
  valentinesText: {
    en: 'Find your loves among our <br />selection of gift sets, candles, body <br />care products, and more.',
  },
  luxeText: {
    en: 'From scented candles to body care <br />products, these products are big on <br />luxury, but easy on your wallet.',
  },
}
