import React from 'react'
import links from 'links'

import BannerBase from 'compositions/banners/BannerBase/BannerBase'

import messages from './messages'

import bgImage from './images/bg.jpg'


const BuyQueueBanner: React.FC<{ onClick?: () => void }> = ({ onClick }) => {
  return (
    <BannerBase
      to={links.buyQueue}
      title={messages.title}
      text={messages.text}
      bgImage={bgImage}
      buttonTitle={messages.buttonTitle}
      content="Buy your queue"
      sizes="560px"
      onClick={onClick}
      data-testid="buyQueueBanner"
    />
  )
}


export default BuyQueueBanner
