import React from 'react'
import links from 'links'

import { buttonMessages } from 'components/inputs'

import BannerBase, { type BannerBaseProps } from 'compositions/banners/BannerBase/BannerBase'

import bgImage from './images/bg.jpg'

import messages from './messages'


type TheEditBannerProps = {
  placement?: string
  'data-testid'?: string
  onClick?: () => void
}

const TheEditBanner: React.FunctionComponent<TheEditBannerProps> = (props) => {
  const { onClick, 'data-testid': dataTestId = 'theEditBanner', placement } = props

  const bannerBaseProps: Omit<BannerBaseProps, 'content'> = {
    title: messages.title,
    text: messages.text,
    bgImage: bgImage,
    buttonTitle: buttonMessages.learnMore,
  }

  return (
    <BannerBase
      to={links.shop.theEdit}
      textColor="black"
      content="TheEdit"
      onClick={onClick}
      placement={placement}
      data-testid={dataTestId}
      {...bannerBaseProps}
    />
  )
}


export default TheEditBanner
