import React, { useRef } from 'react'
import { Popover } from '@headlessui/react'
import { usePathname, useSearchParams } from 'router'
import { sanitizeForAnalytics } from 'helpers'

import { track } from 'analytics'
import { NavList, NavDivider, NavItem } from 'compositions/navigation'
import { useDesktopNav } from '../../util'

import NavRootItem from '../NavRootItem/NavRootItem'


const Nav: React.FunctionComponent = () => {
  const pathname = usePathname()
  const [ searchParams ] = useSearchParams()

  const items = useDesktopNav()
  const groupOpenedPopoverCountRef = useRef<number>(0)

  const activeTabLink = 'tab' in searchParams ? `${pathname}?tab=${searchParams.tab}` : pathname

  return (
    <Popover.Group as={NavList} className="ml-32 flex flex-1 items-stretch self-stretch" listClassName="flex items-stretch" aria-label="Main">
      {
        items.map((item) => {
          if ('isDivider' in item) {
            return (
              <NavDivider key="divider" className="mx-12 self-center" />
            )
          }

          const { id, title, to, list, content } = item

          const isActive = to === activeTabLink || list?.some(({ to }) => (
            // this check required because we have different approach to pages with tabs:
            // - same pathname with tabs in perfumes/colognes subNav
            // - only pathname without tabs (but page has tabs), e.g. discover/brands
            to?.includes('?tab') ? to === activeTabLink : to === pathname
          ))

          if (list) {
            return (
              <li key={id} className="flex items-stretch">
                <NavRootItem
                  className="px-12"
                  style="p2"
                  color="white"
                  hoverColor="gold-30"
                  activeColor="gold-30"
                  data={item}
                  background={item.background}
                  groupOpenedPopoverCountRef={groupOpenedPopoverCountRef}
                  data-testid={id}
                />
              </li>
            )
          }

          return (
            <li key={id} className="flex items-stretch px-12">
              <NavItem
                className="flex items-center"
                style="p2"
                color="white"
                content={content}
                hoverColor="gold-30"
                activeColor="gold-30"
                to={to}
                title={title}
                isActive={isActive}
                onClick={() => {
                  track('Desktop navigation menu click', {
                    blockName: sanitizeForAnalytics(typeof title === 'string' ? title : title.en),
                    title: sanitizeForAnalytics(typeof title === 'string' ? title : title.en),
                    link: to,
                  })
                }}
                data-testid={id}
              />
            </li>
          )
        })
      }
    </Popover.Group>
  )
}


export default Nav
