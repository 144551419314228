import React from 'react'
import links from 'links'
import { useUser } from 'modules/user'

import BannerBase from 'compositions/banners/BannerBase/BannerBase'

import messages from './messages'
import bgImage from './images/bg.jpg'


const QuizBanner: React.FC<{ onClick?: () => void }> = ({ onClick }) => {
  const { hasScentProfile } = useUser()

  return (
    <BannerBase
      to={links.smartRecommendations}
      title={messages.title}
      text={messages.text}
      bgImage={bgImage}
      buttonTitle={hasScentProfile ? messages.retakeQuiz : messages.takeQuiz}
      content="Quiz"
      sizes="560px"
      onClick={onClick}
      data-testid="quizBanner"
    />
  )
}


export default QuizBanner
