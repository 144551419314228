export default {
  title: {
    en: 'The gift that keeps\non giving',
  },
  text: {
    en: `
      With a Scentbird gift\nsubscription, your recipient\ncan take the lead and choose\nthe all fragrances
      they want\nto try for a three, six, or\ntwelve-month duration.
    `,
  },
  buttonTitle: {
    en: 'Shop gift subscriptions',
  },
}
