import React from 'react'
import links from 'links'
import { useDevice } from 'device'
import { useSubscribeOffer } from 'modules/subscription'

import SubscribeHeaderBannerBase from 'compositions/banners/SubscribeHeaderBannerBase/SubscribeHeaderBannerBase'

import messages from './messages'


type TextByOffer = Record<SubscriptionModule.OfferType, { desktop: Intl.Message, mobile: Intl.Message } | Intl.Message>

type SubscribeBannerProps = {
  placement?: 'Header' | 'Under logo'
  withCountdown?: boolean
  withArrow?: boolean
  countdownCookieName?: string
}

const textByOffer: TextByOffer = {
  freeTrial: messages.freeTrial,
  free2ndMonth: messages.freeMonth,
  freeProduct: messages.freeProduct,
  discount: messages.discount,
}

const SubscribeHeaderBanner: React.FC<SubscribeBannerProps> = (props) => {
  const { placement = 'Header', withCountdown, withArrow = true, countdownCookieName } = props

  const { isMobile } = useDevice()

  const {
    isFetching,
    discountText,
    eventDiscountText,
    couponCode,
    formattedPrice,
    formattedExtraShippingPrice,
    offerType,
    handleSubscribeClick,
  } = useSubscribeOffer()

  const deviceType = isMobile ? 'mobile' : 'desktop'

  const title = {
    ...textByOffer[offerType]?.[deviceType] || messages.noDiscountText,
    values: {
      discount: discountText,
      price: formattedPrice,
      extraShippingPrice: formattedExtraShippingPrice,
    },
  }

  return (
    <SubscribeHeaderBannerBase
      title={title}
      loading={isFetching}
      withArrow={withArrow}
      withCountdown={withCountdown}
      countdownCookieName={countdownCookieName}
      trackParams={{
        placement,
        content: 'Subscribe',
        action: 'link',
        link: links.subscribe,
        discount: eventDiscountText,
        coupon: couponCode,
      }}
      onClick={handleSubscribeClick}
    />
  )
}


export default SubscribeHeaderBanner
