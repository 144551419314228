import React from 'react'
import cx from 'classnames'


type NavDividerProps = {
  className: string
}

const NavDivider: React.FunctionComponent<NavDividerProps> = (props) => {
  const { className } = props

  return (
    <li className={cx('border-solid-gray-80 desktop:h-32', className)} role="separator" />
  )
}


export default NavDivider
