import React from 'react'
import links, { getLinkWithParams } from 'links'

import { buttonMessages } from 'components/inputs'

import BannerBase from 'compositions/banners/BannerBase/BannerBase'

import brandsMessages from './messages'

import perfumesBg from './images/perfumes.jpg'
import colognesBg from './images/colognes.jpg'


const banners = {
  perfumes: {
    to: getLinkWithParams(links.brand, { name: 'skylar' }),
    bgImage: perfumesBg,
    messages: brandsMessages.perfumes,
    brandName: 'Skylar',
  },
  colognes: {
    to: getLinkWithParams(links.brand, { name: 'parfums-de-marly' }),
    bgImage: colognesBg,
    messages: brandsMessages.colognes,
    brandName: 'Parfums de Marly',
  },
} as const

type BrandsBannerProps = {
  placement: keyof typeof banners
  onClick?: () => void
}

const BrandsBanner: React.FunctionComponent<BrandsBannerProps> = ({ placement, onClick }) => {
  const { to, bgImage, messages, brandName } = banners[placement]

  return (
    <BannerBase
      to={to}
      title={messages.title}
      text={messages.text}
      textColor="black"
      bgImage={bgImage}
      buttonTitle={buttonMessages.shopNow}
      content="Featured brand"
      sizes="560px"
      onClick={onClick}
      trackParams={{ productBrand: brandName }}
      data-testid="brandsBanner"
    />
  )
}


export default BrandsBanner
