import logger from 'logger'


const localeArray: Intl.LocalePreferences[] = [
  {
    locale: 'en-US',
    country: 'US',
    currency: 'USD',
  },
  {
    locale: 'en-CA',
    country: 'CA',
    currency: 'USD',
  },
  {
    locale: 'en-GB',
    country: 'GB',
    currency: 'GBP',
  },
]

export const localeMap = localeArray.reduce((result, item) => {
  result.set(item.locale, item)
  return result
}, new Map<string, Intl.LocalePreferences>())

export const getSupportedLocale = (localeCode: string | null | undefined, defaultLocaleCode: Intl.LocaleCode = 'en-US'): Intl.LocaleCode => {
  if (localeCode) {
    try {
      const locale = new Intl.Locale(localeCode)
      if (localeMap.has(locale.baseName)) {
        return locale.baseName as Intl.LocaleCode
      }
    }
    catch (error) {
      logger.warn(error, `Invalid locale code ${localeCode}`)
    }
  }

  return defaultLocaleCode
}

export const getLocalePreferences = (locale: Intl.LocaleCode) => localeMap.get(locale)

// No need for now
// export const getLocaleByCountry = (country: string) => localeArray.find((item) => item.country === country)?.locale
//
// export const getLocaleByHeaders = (headers: Record<string, string | undefined>): Intl.LocaleCode | undefined => {
//   const countryCode = headers['x-geoip-country-code']
//   const continentCode = headers['x-geoip-continent-code']
//
//   if (!countryCode && !continentCode) {
//     return undefined
//   }
//
//   const localeByCountry = getLocaleByCountry(countryCode)
//
//   if (localeByCountry) {
//     return localeByCountry
//   }
//
//   if (continentCode === 'EU') {
//     return 'en-GB'
//   }
//
//   return 'en-US'
// }
