import React from 'react'
import links from 'links'
import cookieStorage from 'cookie-storage'
import { constants } from 'helpers'
import { track } from 'analytics'
import { useSubscription } from 'modules/subscription'

import { type IconColor, Logo } from 'components/dataDisplay'


type LogoLinkProps = {
  className?: string
  to?: string
  color: IconColor
}

const LogoLink: React.FunctionComponent<LogoLinkProps> = (props) => {
  const { className, to: customLink, color } = props

  const { subscription } = useSubscription()

  const defaultLink = subscription?.hasNeverSubscribed === false
    ? links.subscription.main
    : (cookieStorage.getItem(constants.cookieNames.logoRedirectUrl) || links.home)

  const to = customLink === undefined ? defaultLink : customLink

  return (
    <Logo
      className={className}
      to={to}
      color={color}
      onClick={() => {
        track('Scentbird logo click')
      }}
    />
  )
}


export default LogoLink
