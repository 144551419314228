export default {
  title: {
    en: 'That scent is so you',
  },
  text: {
    en: 'Learn about the fragrance\nfamilies you gravitate towards,\nand find scents that fit your\npreferences.',
  },
  takeQuiz: {
    en: 'Take the quiz',
  },
  retakeQuiz: {
    en: 'Retake the quiz',
  },
}
