import React from 'react'
import { useSearchParams } from 'router'
import { useUser } from 'modules/user'
import { localeMap, useLocalePreferences } from 'modules/localePreferences'

import { HoverablePopover } from 'components/navigation'
import { NavItem } from 'compositions/navigation'


const LocaleNav: React.FC = () => {
  const [ _, setSearchParams ] = useSearchParams()
  const { localePreferences, setLocalePreferences } = useLocalePreferences()
  // locale selection is only available for guests, otherwise it goes from the user's preferences
  const { isLoggedIn } = useUser()

  return (
    <HoverablePopover className="align-center relative flex h-full justify-center opacity-0 hover:opacity-100">
      {
        ({ close }) => (
          <>
            <HoverablePopover.Button className="text-h5 mx-8" data-testid="localeMenuBtn">
              🌎
            </HoverablePopover.Button>
            <HoverablePopover.Panel as="nav" className="absolute right-0 top-full w-[120rem] bg-black pb-8 pt-32 text-center">
              <div className="text-p5 text-gray-70 px-8">
                Locale: {localePreferences.locale}<br />
                Currency: {localePreferences.currency}<br />
                Country: {localePreferences.country}<br />
              </div>
              {
                !isLoggedIn && (
                  <div>
                    {
                      Array.from(localeMap.keys()).map((locale) => (
                        <NavItem
                          key={locale}
                          title={locale}
                          className="block py-6"
                          style="p2"
                          hoverColor="gold-30"
                          activeColor="gold-30"
                          labelColor="gold-30"
                          isActive={localePreferences.locale === locale}
                          onClick={() => {
                            setLocalePreferences(() => localeMap.get(locale))
                            setSearchParams({ locale: locale })
                            close()
                          }}
                        />
                      ))
                    }
                  </div>
                )
              }
            </HoverablePopover.Panel>
          </>
        )
      }
    </HoverablePopover>
  )
}


export default LocaleNav
