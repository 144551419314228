export default {
  discount: {
    mobile: {
      en: 'Get {discount} off your first month',
    },
    desktop: {
      en: 'Get {discount} off your first month of Scentbird',
    },
  },
  freeTrial: {
    mobile: {
      en: 'Get your free perfume now',
    },
    desktop: {
      en: 'Get your free perfume now. Your first month is free, just pay {extraShippingPrice} for shipping and handling',
    },
  },
  freeProduct: {
    mobile: {
      en: 'Subscribe and unlock your free product',
    },
    desktop: {
      en: 'Subscribe and unlock a free product in your first order',
    },
  },
  freeMonth: {
    mobile: {
      en: 'Unlock your second month for free',
    },
    desktop: {
      en: 'Buy one, get one free! Monthly supply of any listed fragrance starting at {price}',
    },
  },
  noDiscountText: {
    en: 'Get your first month of Scentbird for {price}',
  },
}
