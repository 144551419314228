export default {
  title: {
    en: 'Pick a color,\nany color',
  },
  text: {
    en: 'Available in a variety of hues, our\natomizer cases ensure your scent is\nalways dressed for the occasion.',
  },
  buttonTitle: {
    en: 'Shop cases',
  },
}
